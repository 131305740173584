<template>
  <button @click="showDeleteConfirmation" class="delete__btn">
    <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 2.83333L17 5.10667M5.45276 3.49333L7.49049 1L11.4905 1.58667L12.6226
        4.44667M11.7925 11.7067C11.704 14.456 11.6545 15.9974 11.566 18.7467M1.52827
        7.45333H16.4717L15.7169 20.8C15.6415 21.9733 14.6603 23 13.4528 23H4.54713C3.26411
        23 2.35845 21.9733 2.28298 20.8L1.52827 7.45333Z"
        stroke="#999999"
        stroke-width="2"
        stroke-miterlimit="22.9256"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  </button>
</template>
<script>
export default {
  methods: {
    showDeleteConfirmation() {
      this.$emit('showDeleteConfirmation')
    }
  }
}
</script>
